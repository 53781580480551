<template>
  <div>
    <div class="holder">
      <div class="dashboard-holder">
        <SmallNav />
        <!-- <div style="">
          <img @click="MoveToSure" style="width: 100%; cursor: pointer;" src="../assets/home/promo-banner.gif" alt="promo">
        </div> -->
        <div class="container-fluid">
          <div class="safe_area">
            <div class="row">
              <div class="col-md-6">
                <div class="safe_right">
                  <h6>Have a Project?</h6>
                  <h3>
                    Execute <span>With </span
                    ><img
                      style="width: 40px;"
                      src="../assets/images/safe2.svg"
                      alt="safe"
                    />
                    Safe
                  </h3>
                </div>
              </div>
              <div class="col-md-6">
                <div @click="goToSafe">
                  <router-link to="/dashboard/safe">
                    <button class="btn safe_btn">
                      GO TO SAFE
                      <img src="../assets/images/safe.svg" alt="safe" />
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Add property and invite link begins -->
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="addproperty">
                <div class="row addprop-wrap">
                  <div class="col-md-6">
                    <p class="connect-text">
                      let’s connect you to the best tenants through the shelta
                      platform.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <img
                      class="addprop-img"
                      src="../assets/images/addproperty.svg"
                      alt="addproperty"
                    />
                  </div>
                </div>
                <div class="add-prop-btn">
                  <button class="btn">
                    <router-link to="/addproperty" class="addpropLink"
                      >Add property</router-link
                    >
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 d-none">
              <div class="invite-link">
                <p>
                  Lets build a community together. invite other landlords to the
                  Shelta platform.
                </p>
                <div class="invite-link-holder">
                  <button @click.prevent="showInviteLink()" class="btn">
                    Send invite link
                  </button>
                </div>
                <div class="col-md-10 m-auto" id="linkIv">
                  <div class="invite-link-div">
                    <p>Enter the recepient’s email address</p>
                    <div class="invite-input-div">
                      <input
                        class="form-control input-bar"
                        type="text"
                        name="invitelink"
                        id="Eg. charlesamos003@gmail.com"
                      />
                    </div>
                    <div class="send-invite-holder">
                      <button
                        class="btn close-invite"
                        @click="showInviteLink()"
                      >
                        Close
                      </button>
                      <button class="btn send-invite" @click="sendInvite()">
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-6 col-lg-6 blue-row">
              <div class="blue-card">
                <p>Watch this space</p>
                <p>Dashboard update on going</p>
              </div>
            </div> -->
          </div>
          <!-- Add property and invite link ends -->

          <!-- Amount paid and maintainers begins -->
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="amount-lastPaid">
                <p>Amount Last Paid</p>
                <h5 v-if="displayAmount2 === true">
                  &#8358;{{ moreDetails.lastpaymemt }}
                </h5>
                <h5 v-if="displayAmount2 === false">X X X X X X X</h5>
                <div class="date-show">
                  <small>{{ moreDetails.lastpaymemt_date }}</small>
                  <form class="form-inline">
                    <div class="custom-control custom-radio mb-3 my-1 mr-sm-2">
                      <input
                        name="show-hide"
                        type="radio"
                        class="custom-control-input"
                        id="customControlInline"
                        v-model="lastpayment"
                        value="show"
                        @change="showLastAmount"
                      />
                      <label
                        class="custom-control-label"
                        for="customControlInline"
                        >Show</label
                      >
                    </div>
                    <div class="custom-control custom-radio mb-3 my-1 mr-sm-2">
                      <input
                        name="show-hide"
                        type="radio"
                        class="custom-control-input"
                        id="customControlInline2"
                        v-model="lastpayment"
                        value="hide"
                        @change="showLastAmount"
                      />
                      <label
                        class="custom-control-label"
                        for="customControlInline2"
                        >Hide</label
                      >
                    </div>
                  </form>
                </div>
                <div class="next-payment">
                  <p>Next Payment</p>
                  <h6 v-if="displayAmount === true">
                    &#8358;{{ moreDetails.nextpayment }}
                  </h6>
                  <h6 v-if="displayAmount === false">X X X X X X X X</h6>
                  <div class="date-show">
                    <small>{{ moreDetails.nextpaymetdate }}</small>
                    <form class="form-inline">
                      <div
                        class="custom-control custom-radio mb-3 my-1 mr-sm-2"
                      >
                        <input
                          name="nextpay"
                          type="radio"
                          class="custom-control-input"
                          id="customControlInline3"
                          v-model="nextpayment"
                          value="show"
                          @change="showNextAmount"
                        />
                        <label
                          class="custom-control-label"
                          for="customControlInline3"
                          >Show</label
                        >
                      </div>
                      <div
                        class="custom-control custom-radio mb-3 my-1 mr-sm-2"
                      >
                        <input
                          name="nextpay"
                          type="radio"
                          class="custom-control-input"
                          id="customControlInline4"
                          v-model="nextpayment"
                          value="hide"
                          @change="showNextAmount"
                        />
                        <label
                          class="custom-control-label"
                          for="customControlInline4"
                          >Hide</label
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="maintainers-report">
                <p>Maintenance Report</p>
                <div class="table-responsive">
                  <h4 class="nomaintainers">
                    You don't have any maintenance yet
                  </h4>
                  <table class="table table-striped">
                    <!-- <thead>
                      <tr class="table-row table-head">
                        <th scope="col">PIN</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">COST</th>
                        <th scope="col">STATUS</th>
                      </tr>
                    </thead> -->

                    <tbody>
                      <!-- <tr class="table-row">
                        <td>1y4n45</td>
                        <td>Repair of leaking kitchen sink</td>
                        <td>0000</td>
                        <td><div class="green-dot"></div></td>
                      </tr>
                      <tr class="table-row">
                        <td>1y4n45</td>
                        <td>Repair of leaking kitchen sink</td>
                        <td>#0000</td>
                        <td><div class="yellow-dot"></div></td>
                      </tr>
                      <tr class="table-row">
                        <td>1y4n45</td>
                        <td>Repair of leaking kitchen sink</td>
                        <td>0000</td>
                        <td><div class="red-dot"></div></td>
                      </tr>
                      <tr class="table-row">
                        <td>1y4n45</td>
                        <td>Repair of Celing lickage</td>
                        <td>#0000</td>
                        <td><div class="green-dot"></div></td>
                      </tr>
                      <tr class="table-row">
                        <td>1y4n45</td>
                        <td>Repair of leaking kitchen sink</td>
                        <td>#0000</td>
                        <td><div class="yellow-dot"></div></td>
                      </tr>
                      <tr class="table-base">
                        
                        <td colspan="4" class="">
                          <button class="btn viewall-maintaners-btn">
                            View All
                          </button>
                        </td>

                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Amount paid and maintainers ends -->

          <!-- Income and Expenses begins -->

          <div class="row d-none">
            <div class="col-md-6 col-lg-6">
              <div class="income-holder">
                <div class="income-wrap">
                  <p>INCOME</p>
                  <p>&#8358;00</p>
                  <P>00-00-0000</P>
                </div>
                <img src="../assets/images/income-img.svg" alt="income" />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="expenses-holder">
                <div class="expenses-wrap">
                  <p>EXPENDITURE</p>
                  <p>&#8358;0</p>
                  <P>00-00-0000</P>
                </div>
                <img src="../assets/images/expenses-img.svg" alt="income" />
              </div>
            </div>
          </div>
          <!-- Income and expenses ends -->

          <div class="propertt-list-holder" v-if="properties.length > 0">
            <div class="container">
              <div class="property-text-wrap">
                <p>Properties</p>
                <router-link to="/dashboard/landlordproperties">
                  <p>View All</p>
                </router-link>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="table-row table-head">
                    <th scope="col">PIN</th>
                    <th scope="col">IMAGE</th>
                    <th scope="col">TITLE</th>
                    <th scope="col">LOCATION</th>
                    <th scope="col">BED</th>
                    <th scope="col">BATH</th>
                    <th scope="col">TOILET</th>
                    <th scope="col">RATE</th>
                    <th scope="col">STATUS</th>
                  </tr>
                </thead>
                <tbody v-for="(property, index) in properties" :key="index">
                  <tr class="table-row">
                    <td>{{ property.pin }}</td>
                    <td>
                      <img
                        class="prop-img-list"
                        :src="property.images[0]"
                        alt="income"
                      />
                    </td>
                    <td>{{ property.title }}</td>
                    <td>{{ property.address }}</td>
                    <td>{{ property.address }}</td>
                    <td>{{ property.bedrooms }}</td>
                    <td>{{ property.bathrooms }}</td>
                    <td>
                      &#8358;{{ Number(property.price).toLocaleString() }}
                    </td>
                    <td v-if="property.status === 'Vacant'">
                      <div class="green-dot"></div>
                    </td>
                    <td v-if="property.status === 'Locked'">
                      <div class="red-dot"></div>
                    </td>
                    <td v-if="property.status === 'Occupied'">
                      <div class="yellow-dot"></div>
                    </td>
                    <td v-if="property.status === 'Leased'">
                      <div class="grey-dot"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="sticky-button" v-if="subscribed == 'NO'">
            <button class="btn" @click="membership">Become a Shelta User</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "Dashboard",
  // props: {
  //   properties
  // },

  components: {
    SmallNav
  },
  data() {
    return {
      uploadFile: "",
      properties: [],
      id: "",
      email: "",
      lastpayment: "hide",
      moreDetails: "",
      // nextpayment: "show",

      nextpayment: "hide",
      displayAmount: false,
      displayAmount2: false,
      subscribed: ""
      // properties: [],

      // showInvite: false;
    };
  },
  mounted() {
    this.checkLogger();
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.getProperties();
    this.landlordDetails();
    // this.subscribed = Api.getSubscriptionData();
    this.subscribed = JSON.parse(localStorage.getItem("shelta-user")).subscribed;
    // this.$root.$on("properties", (response) => {
    //   console.log("Emitting properties", response);
    // });

    // const Hi = this.$root.$refs.Properies.getProperties();
    // this.$root.$on("getProperties", () => {

    //   return this.getProperties();
    // });
    // console.log("Getting arguments from components", Hi);
  },
  methods: {
    checkLogger() {
      Api.postRequest(`logcounter`, {})
        .then(res => {
          // console.log("Who reads", res);
        })
        .catch(err => {
          console.log(err);
          this.loader = false;
        });
    },
    membership() {
      this.$root.$refs.D.openSubModal();
    },
    MoveToSure() {
      this.$router
        .push({
          path: `/sure`
        })
        .catch(() => {});
    },
    goToSafe() {
      this.$gtag.event("dashboard-initial-click", {
        event_category: "safe documentaion clicks",
        event_label: "Go To Safe Button Clicked",
        value: 1
      });
    },
    landlordDetails: async function() {
      try {
        await Api.getRequest(`fetchmorelandlorddatabyid/${this.id}`)
          .then(res => {
            // console.log('Details', res);
            this.moreDetails = res.data;
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    // sendInvite: async function() {
    //   console.log("Invite");
    //   this.loader = true;
    //   try {
    //     await Api.postRequest(`invitelink`, {
    //       email: this.email
    //     })
    //       .then(res => {
    //         this.loader = false;
    //         console.log("Invite", res);
    //       })
    //       .catch(err => {
    //         console.log(err);
    //         this.loader = false;
    //       });
    //   } finally {
    //     // console.log("Closed connection");
    //   }
    // },
    showNextAmount: function() {
      if (this.nextpayment === "show") {
        this.displayAmount = true;
      } else {
        this.displayAmount = false;
      }
    },
    showLastAmount: function() {
      if (this.lastpayment === "show") {
        this.displayAmount2 = true;
      } else {
        this.displayAmount2 = false;
      }
    },

    showInviteLink: function() {
      const invite = document.getElementById("linkIv");
      if (invite.style.display === "none") {
        invite.style.display = "block";
      } else {
        invite.style.display = "none";
      }
    },
    getProperties: async function() {
      try {
        await Api.getRequest(`fetchpropertiesbylandlordid/${this.id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "Loading properies...");
            // console.log("Properties", res);
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.properties = res.data.properties;
              // Dont Remove this commented file;
              // const cool = res.data.properties;
              // const x = cool.slice(0, 3);
              // this.properties = x;
              // // console.log("sliced array", this.properties);
              // const member = "my name is Mate";
              // const last2 = member.slice(-2);
              // console.log("last twomin array", last2)
              this.$store.commit("setApiSuccess", "Propeties loades");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
$fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
$fontFamily2: url("https://fonts.googleapis.com/css2?family=Gotham-Ultra:wght@100&display=swap");


.sticky-button {
  position: fixed;
    right: 2%;
    bottom: 3%;
    z-index: 10;
  button {
    width: 240px;
    height: 64px;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    border-radius: 40px;
    background: #0033ea;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04),
      0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 599px) {

  .sticky-button {
    button {
      width: 186px;
      height: 46px;
    }
  }
}

// Countdowndashboard
.safe_area {
  position: relative;
  background-image: url("../assets/images/full-blue-card.svg");
  background-repeat: no-repeat;
  // height: 100%;
  min-height: 166.4px;
  // background-size: cover;
  // height: 100vh;
  border-radius: 10px;
  // display: grid;
  // margin-bottom: 1rem;
  padding: 44px 10px 0;
  margin-top: 20px;
}

.safe_right {
  text-align: left;
  padding: 0 30px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    color: $secondary;
  }
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 100%;
    color: $secondary;
    span {
      color: #ffb100;
    }
    img {
      padding: 0 0px 15px 0px;
    }
  }
}

.safe_btn {
  color: $secondary;
  background: #3ec250;
  // color: $black;
  // background: #ffb100;
  font-family: Gotham;
  font-style: normal;
  font-size: 18px;
  margin-top: 20px;
  img {
    padding-bottom: inherit;
  }
}

.blue-row {
  margin: 20% auto;
}
.blue-card {
  background: blue;
  color: #ffffff;
  height: 11rem;
  border-radius: 10px;
  padding-top: 2.5rem;
}
.blue-card > p:first-child {
  font-size: 30px;
}
.blue-card > p:nth-child(2) {
  font-size: 23px;
}
// countdownboard

.addproperty {
  background: linear-gradient(180deg, #0033ea 0%, #000000 131.31%), #ffffff;
  border-radius: 10px;
  margin-top: 2rem;
}
.addprop-wrap {
  position: relative;
  padding: 1rem;
}
.connect-text {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  // display: flex;
  text-align: initial;
  align-items: center;
  color: $secondary;
}
.add-prop-btn {
  display: flex;
  position: absolute;
  bottom: 10%;
  left: 6%;
}
.add-prop-btn > button {
  color: $primary;
  background: $secondary;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  height: 32px;
  // display: flex;
  align-items: center;
  color: #0033ea;
}
.addpropLink {
  color: $primary;
  text-decoration: none;
}

.invite-link {
  background: linear-gradient(180deg, #0033ea 0%, #000000 131.31%), #ffffff;
  border-radius: 10px;
  max-height: 174px;
  margin-top: 2rem;
  padding-bottom: 3rem;
}
.invite-link > p {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  color: $secondary;
  padding: 1rem 5rem;
}

.invite-link-holder > button {
  background: $secondary;
  color: $primary;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  align-items: center;
  height: 32px;
  line-height: 14px;
}

// .invie-link-div {
//   top: 2rem;
//   background: $secondary;
//   position: relative;
//   z-index: 3;
//   height: 177px;
//   box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25)
// }
.invie-link-div > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $black;
}
.invite-input-div > input {
  border-radius: 0;
  box-shadow: none;
}
#linkIv {
  display: none;
}
.invite-link-div {
  background: $secondary;
  z-index: 3;
  position: relative;
  top: 0.7rem;
  padding: 1rem;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.invite-link-div::after {
  content: "";
  border-width: 10px;
  margin-left: -5px;
  border-color: transparent transparent $secondary transparent;
  border-style: solid;
  position: absolute;
  bottom: 100%;
  left: 50%;
}
.invite-input-div {
  padding: 0 2rem;
}

.close-invite {
  background: $yellow;
  color: $black;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  // line-height: 30px;
  border-radius: 100px;
  width: 98px;
  margin-top: 2rem;
  margin-right: 10px;
}
.send-invite {
  background: $primary;
  color: $secondary;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  // line-height: 30px;
  border-radius: 100px;
  width: 98px;
  margin-top: 2rem;
  margin-right: 10px;
}

.amount-lastPaid {
  background: $secondary;
  border-radius: 10px;
  margin-top: 2rem;
}
.amount-lastPaid > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $black;
  padding: 1rem 0 0 1rem;
}
.amount-lastPaid > h5:nth-child(2) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: $black;
  padding: 1rem 0 0 1rem;
}

.radio-wrap {
  display: flex;
}
.date-show {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.next-payment > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $black;
  padding: 1rem 0 0 1rem;
}

.next-payment > h6:nth-child(2) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: $black;
  padding: 1rem 0 0 1rem;
}
.maintainers-report {
  background: $secondary;
  border-radius: 10px;
  margin-top: 2rem;
  max-height: 22rem;
  height: 100%;
  overflow-y: scroll;
}
.maintainers-report > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: black;
  padding: 1rem;
}

.table-row {
  text-align: start;
}
.table-head > th:last-child {
  text-align: center;
}
.table-head > th {
  border-top: none;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  align-items: center;
  color: black;
}
.table-row > td {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: $black;
}

.green-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #2fc643;
  margin: auto;
}
.yellow-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ffd704;
  margin: auto;
}
.grey-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #8a8787;
  margin: auto;
}

.red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ea0000;
  margin: auto;
}
.table-base > td {
  background: linear-gradient(180deg, #0033ea 0%, #000000 131.31%), #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
}
.viewall-maintaners-btn {
  color: $secondary;
  cursor: pointer;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  align-items: center;
  color: #ffffff;
}

.income-holder {
  background: $secondary;
  border-radius: 10px;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 0 2rem;
}

.income-wrap > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $black;
}
.income-wrap > p:nth-child(2) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $black;
}
.income-wrap > p:nth-child(3) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: $black;
}

.expenses-holder {
  background: $secondary;
  border-radius: 10px;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 0 2rem;
}

.expenses-wrap > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $black;
}
.expenses-wrap > p:nth-child(2) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $black;
}
.expenses-wrap > p:nth-child(3) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: $black;
}

.property-text-wrap {
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
}
.propertt-list-holder {
  background: $secondary;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.property-text-wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.property-text-wrap > p:nth-child(2) {
  cursor: pointer;
}
.property-text-wrap p {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #000000;
}

.prop-img-list {
  width: 4rem;
  height: 2rem;
  border-radius: 5px;
  object-fit: cover;
}

.nomaintainers {
  font-family: Gotham;
  font-weight: normal;
  font-size: 15px;
  text-align: left;
  margin: auto;
  padding-left: 1rem;
}

.custom-control-label {
  font-family: Gotham;
}

@media only screen and (max-width: 599px) {
  .safe_area {
    padding: 10px 10px;
  }
  .safe_right {
    padding: 15px 10px;
    h6 {
      font-size: 25px;
    }
    h3 {
      font-size: 27px;
      span {
        // padding-right: 10px;
      }
      img {
        display: none;
      }
    }
  }
  .safe_btn {
    margin-top: 0;
    font-size: 15px;
  }
  .addprop-img {
    width: 6rem;
    position: relative;
    top: -2rem;
  }
  .add-prop-btn {
    bottom: 3%;
    left: 35%;
  }
  .invite-link > p {
    padding: 1rem 1rem;
    text-align: left;
  }
  .table-row > td {
    white-space: pre !important;
  }
  .nomaintainers {
    font-family: Gotham;
    font-weight: normal;
    font-size: 19px;
    text-align: left;
    margin: auto;
    padding: 0px 14px 0 14px;
  }
  .form-inline {
    div:first-child {
      padding-right: 0.7rem;
    }
  }
  .income-holder {
    margin-top: 4rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .addprop-wrap {
    height: 13rem;
  }
  .addprop-img {
    width: 6rem;
    position: relative;
    top: 0rem;
  }
  .add-prop-btn {
    left: 35%;
    bottom: 8%;
  }
  .connect-text {
    font-size: 14px;
  }
  .invite-link {
    min-height: 207px;
  }
  .invite-link-holder {
    padding-top: 2rem;
  }
  .invite-link > p {
    font-size: 14px;
    padding: 1rem 1rem;
  }
  .table-row > td {
    white-space: pre !important;
  }
}

// @media screen and (min-device-width: 768px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {

// }

// .dashboard-holder {
//   margin: 2rem 2rem 4rem 2rem;
// }

// .white-card {
//   background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
//   border: 1px solid #e5e5e5;
//   box-sizing: border-box;
// }

// .card {
//   border-radius: 10px !important;
//   // border: none;
// }

// .body-child {
//   // width: 35.46rem;
//   min-height: auto;
//   margin: auto;
//   // display: none;
// }
// .my-card-body {
//   box-sizing: border-box;
//   padding-bottom: 6rem;
// }

// .next-rent-wrap {
//   padding: 1rem;
// }
// .next-rent-top {
//   display: flex;
//   justify-content: space-between;
// }
// .next-rent-top > p:first-child {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 11px;
//   align-items: center;
//   color: $black;
// }
// .next-rent-top > p:nth-child(2) {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   line-height: 11px;
//   align-items: center;
//   color: $black;
// }

// .rent-amount {
//   display: flex;
//   padding: 3rem 0 3rem 0;
// }
// .rent-amount > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 35px;
//   line-height: 33px;
//   align-items: center;
//   color: $black;
// }

// .paynow-holder {
//   display: flex;
//   justify-content: space-between;
// }
// .paynow-holder > p:first-child {
//   background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
//   width: 121px;
//   height: 36px;
//   line-height: 40px;
//   color: #ffffff;
//   border-radius: 10px;
//   margin-bottom: 0;
// }
// .paynow-holder > p:nth-child(2) {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 36px;
//   align-items: center;
//   color: $black;
//   margin-bottom: 0;
// }
// .next-rent-bottom {
//   padding: 1rem;
// }
// .next-rent-bottom > p:first-child {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 11px;
//   display: flex;
//   align-items: center;
//   color: $black;
// }
// .last-paid-rent {
//   display: flex;
//   padding-top: 3rem;
// }
// .last-paid-rent > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 35px;
//   line-height: 33px;
//   align-items: center;
//   color: $black;
// }
// .last-paid-date {
//   display: flex;
//   justify-content: space-between;
//   padding-top: 4rem;
// }
// .last-paid-date > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 11px;
//   display: flex;
//   align-items: center;
//   color: $black;
// }

// // Right top section begins
// .notification-section {
//   display: flex;
//   justify-content: space-between;
//   background: $primary;
//   border-radius: 10px 10px 0px 0px;
//   height: 43px;
//   padding: 1rem;
//   // padding: 0 1rem 1rem 1rem;
// }
// .notification-section > p:first-child {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 11px;
//   display: flex;
//   align-items: center;
//   color: $secondary;
//   margin-bottom: 0;
// }

// .notification-text-holder {
//   padding: 1rem;
//   display: flex;
// }
// .notification-text-holder > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 17px;
//   line-height: 30px;
//   text-align: start;
//   // display: flex;
//   justify-content: flex-start;
//   color: $fontFamily;
//   margin-bottom: 0;
// }
// .notification-arrow {
//   display: flex;
//   justify-content: space-between;
//   padding: 0 1rem 1rem 1rem;
// }

// .switch-holder {
//   padding-top: 2rem;
// }

// .switch-card {
//   // padding-top: 3rem;
//   border: none;
//   background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
// }

// .switch-text {
//   padding: 1.5rem 0 0rem 1.5rem;
// }
// .switch-text > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 25px;
//   text-align: start;
//   align-items: center;
//   color: #ffffff;
//   margin-bottom: 0;
// }
// .switch-img-holder {
//   display: flex;
//   justify-content: space-evenly;
// }
// .payment-duration {
//   padding: 0.3rem 0 1rem 1.5rem;
// }
// .payment-duration > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 25px;
//   text-align: start;
//   align-items: center;
//   color: #ffffff;
//   margin-bottom: 0;
// }

// .last-slab {
//   padding-top: 2rem;
// }

// .maintain-facility-wrap {
//   display: flex;
//   justify-content: space-evenly;
//   padding: 3rem 0rem 4rem 1rem;
// }

// .maintainFacility {
//   padding-top: 1rem;
// }

// .maintainFacility > p:first-child {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 20px;
//   line-height: 10px;
//   color: $secondary;
// }

// .maintainFacility > p:nth-child(2) {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 35px;
//   line-height: 19px;
//   color: $secondary;
// }

// .save-rent-text {
//   padding-top: 1rem;
// }
// .save-rent-text > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 30px;
//   line-height: 29px;
//   align-items: center;
//   color: $black;
// }

// .live-in-peace > p {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   line-height: 35px;
//   align-items: center;
//   padding-top: 0.5rem;
//   color: $black;
// }

// ::-webkit-input-placeholder {
//   /* Edge */
//   font-family: $fontFamily;
//   font-style: italic;
//   font-weight: 300;
//   font-size: 13px;
//   line-height: 13px;
//   color: rgba(0, 0, 0, 0.5);
//   text-decoration-line: underline;
// }

// :-ms-input-placeholder {
//   /* Internet Explorer */
//   font-family: $fontFamily;
//   font-style: italic;
//   font-weight: 300;
//   font-size: 13px;
//   line-height: 13px;
//   color: rgba(0, 0, 0, 0.5);
//   text-decoration-line: underline;
// }

// ::placeholder {
//   font-family: $fontFamily;
//   font-style: italic;
//   font-weight: 300;
//   font-size: 13px;
//   line-height: 13px;
//   color: rgba(0, 0, 0, 0.5);
//   text-decoration-line: underline;
// }

// @media only screen and (max-width: 599px) {
//   .dashboard-holder {
//     margin: 2rem 0 4rem 0;
//   }
//   .mobile-flex {
//     flex-wrap: nowrap !important;
//   }
// }

// @media screen and (min-width: 1200px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 1) {
// }
// @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
//   .mobile-flex {
//     flex-wrap: wrap !important;
//   }
// }
</style>
